export const CLIENT_KEY = process.env.NEXT_PUBLIC_CLIENT_KEY;

export const APP_DOMAIN = process.env.AUTH_URL || process.env.NEXT_PUBLIC_DOMAIN_URL;

export const ONBOARD_KEY = process.env.ONBOARD_KEY;

export const PAYSTACK_PUBLIC_KEY = process.env.PAYSTACK_PUBLIC_KEY;

export const BANK_TRANSFER_CHARGE = process.env.NEXT_PUBLIC_BANK_TRANSFER_CHARGE;

export const GOOGLE_MAPS_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '';
export const MICROSOFT_CLARITY = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY ?? '';
export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ?? '';
export const GOOGLE_ADSENSE_PUB_ID = process.env.NEXT_PUBLIC_GOOGLE_ADSENSE_PUB_ID ?? '';
export const MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '';

export const MAX_IMAGE_IN_MEGABYTE = parseInt(process.env.NEXT_PUBLIC_MAX_IMAGE_SIZE_MB || '2');
export const MAX_NUMBER_POST_IMAGES = parseInt(
  process.env.NEXT_PUBLIC_MAX_NUMBER_POST_IMAGES || '5'
);
export const MAX_IMAGE_IN_BYTE = MAX_IMAGE_IN_MEGABYTE * 1024 * 1024;

export const MINUTES_BTW_CTA_POPUP = parseInt(process.env.NEXT_PUBLIC_MINUTES_BTW_CTA_POPUP || '1');

export const FIREBASE_API_KEY = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
export const FIREBASE_AUTH_DOMAIN = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
export const FIREBASE_DATABASE_URL = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
export const FIREBASE_PROJECT_ID = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
export const FIREBASE_STORAGE_BUCKET = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
export const FIREBASE_MESSAGING_SENDER_ID = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
export const FIREBASE_APP_ID = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;

export const FUSION_API = {
  apiMicroserviceUserSecurity: process.env.NEXT_PUBLIC_USER_SECURITY_BASE_URL,
  apiMicroserviceUserSettings: process.env.NEXT_PUBLIC_USER_SETTINGS_BASE_URL,
  apiMicroserviceAdmin: process.env.NEXT_PUBLIC_ADMIN_BASE_URL,
  apiMicroserviceOnboarding: process.env.NEXT_PUBLIC_ONBOARDING_BASE_URL,
  apiKey: process.env.NEXT_PUBLIC_FUSION_API_KEY,
  endpoint: process.env.NEXT_PUBLIC_FUSION_ENDPOINT,
  communitiesApi: process.env.NEXT_PUBLIC_COMMUNITIES_BASE_URL,
  apiMicroServiceWallet: process.env.NEXT_PUBLIC_WALLETS_BASE_URL,
  apiMicroServiceCommunity: process.env.NEXT_PUBLIC_COMMUNITIES_BASE_URL,
};

export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

export const AMPLIFY_API = {
  userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  callbackUrl: process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,
};

export const USER_ROLES = {
  admin: 2,
  user: 1,
};
